import { useState, useEffect, useReducer, createContext } from "react"
import { AppReducer } from "./reducer"

// initial state
const initialState = {
  listing: {},
  listingView: false,
}

// create context
const Context = createContext({})

// context provider
const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState)
  const value = { state, dispatch }

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("state"))) {
      //checking if there already is a state in localstorage
      //if yes, update the current state with the stored one
      dispatch({
        type: "init_stored",
        payload: JSON.parse(localStorage.getItem("state")),
      })
    }
  }, [])

  useEffect(() => {
    if (state !== initialState) {
      localStorage.setItem("state", JSON.stringify(state))

      //create and/or set a new localstorage variable called "state"
    }
  }, [state])

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export { Context, ContextProvider }

import { GrowthBook } from "@growthbook/growthbook-react"
import TagManager from "react-gtm-module"

const growthbook = new GrowthBook({
  apiHost: "https://cdn.growthbook.io",
  clientKey: "sdk-aqhbhDkXcBAQ2SJ",
  enableDevMode: true,
  subscribeToChanges: true,
  backgroundSync: false,
  trackingCallback: (experiment, result) => {
    console.log("experiment", experiment)

    TagManager.dataLayer({
      dataLayer: {
        event: "experiment_viewed",
        experimentId: experiment.key,
        variationId: result.key,
      },
    })
  },
})

export default growthbook

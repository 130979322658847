// components/ErrorBoundary.js
import { Component } from "react"
import * as Sentry from "@sentry/nextjs"

class ErrorBoundary extends Component {
  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught by ErrorBoundary:", error, errorInfo)

    if (process.env.NODE_ENV === "production") {
      Sentry.captureException(error, { extra: errorInfo })
    }
  }

  render() {
    if (this?.state?.hasError) {
      const { fallback: FallbackComponent } = this.props

      if (FallbackComponent) {
        return <FallbackComponent error={this.state.error} />
      }

      return <h1>Something went wrong.</h1>
    }
    return this.props.children
  }
}

export default ErrorBoundary

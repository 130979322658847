import translate from "@/utils/translation"
import { Switch } from "@mui/material"
import React, { useEffect, useState } from "react"
import TagManager from "react-gtm-module"
import styled from "styled-components"

const Consent = () => {
  const [showModal, setModal] = useState(false)
  const [showBanner, setBanner] = useState(false)

  if (typeof window === "undefined") {
    return null
  } else {
    const consentCookieRAW = localStorage.getItem("consent")
    const consentCookie = JSON.parse(consentCookieRAW)

    const [isMobile, setIsMobile] = useState(window?.innerWidth < 768)

    const [consent, setConsent] = useState({
      functional_storage: consentCookie?.functional_storage || "granted",
      ad_storage: consentCookie?.ad_storage || "denied",
      analytics_storage: consentCookie?.analytics_storage || "denied",
      personalization_storage: "denied",
      security_storage: "denied",
    })

    useEffect(() => {
      if (!consentCookie) {
        setBanner(true)
      }

      const handleResize = () => {
        setIsMobile(window.innerWidth < 768)
      }

      window.addEventListener("resize", handleResize)
      return () => {
        window.removeEventListener("resize", handleResize)
      }
    }, [])

    const handleConsent = consent => {
      setConsent(consent)
      setModal(false)
      setBanner(false)

      //save cookie preferences into the local storage
      localStorage.setItem("consent", JSON.stringify(consent))

      //send cookie preferences to google tag manager gtag.js
      TagManager.dataLayer({
        dataLayer: {
          event: "gtm_consent_update",
          consent: consent,
        },
      })
    }

    return (
      <>
        {!showModal && showBanner && (
          <Wrapper>
            <div className="inner">
              <h4>{translate("cookies_settings")}</h4>
              <p>
                {isMobile
                  ? translate("cookies_summary_mobile")
                  : translate("cookies_summary")}
                <a
                  target="_blank"
                  href="/policy/terms#cookies"
                  rel="noopener noreferrer"
                >
                  {translate("cookies_privacy_policy")}
                </a>
                .
              </p>
              <div className=" d-flex justify-content-start">
                <div
                  className="btn rounded-pill me-2"
                  onClick={() => {
                    setModal(true), setBanner(false)
                  }}
                >
                  {translate("cookies_customize")}
                </div>
                <div
                  className="btn rounded-pill highlight ms-2"
                  onClick={() =>
                    handleConsent({
                      functional_storage: "granted",
                      analytics_storage: "granted",
                      ad_storage: "granted",
                    })
                  }
                >
                  {translate("cookies_accept")}
                </div>
              </div>
            </div>
          </Wrapper>
        )}
        {showModal && (
          <Modal>
            <div className="inner">
              <div className="content">
                <h3>{translate("cookies_title")}</h3>
                <div className="items mt-4">
                  <div className="item row">
                    <div className="explanation col-md-11 col-12 order-2 order-md-1">
                      <h4>{translate("cookies_essential")}</h4>
                      <p>{translate("cookies_essential_text")}</p>
                    </div>
                    <div className="toggle col-12 col-md-1 mb-3 mb-md-0 text-start text-md-end order-1 order-md-2">
                      <Switch color="warning" disabled defaultChecked />
                    </div>
                  </div>
                  <hr />
                  <div className="item row">
                    <div className="explanation col-md-11 col-12 order-2 order-md-1">
                      <h4>{translate("cookies_analytics")}</h4>
                      <p>{translate("cookies_analytics_text")}</p>
                    </div>
                    <div className="toggle col-12 col-md-1 mb-3 mb-md-0 text-start text-md-end order-1 order-md-2">
                      <Switch
                        color="warning"
                        onChange={e => {
                          setConsent({
                            ...consent,
                            analytics_storage: e.target.checked
                              ? "granted"
                              : "denied",
                          })
                        }}
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="item row">
                    <div className="explanation col-md-11 col-12 order-2 order-md-1">
                      <h4>{translate("cookies_marketing")}</h4>
                      <p>{translate("cookies_marketing_text")}</p>
                    </div>
                    <div className="toggle col-12 col-md-1 mb-3 mb-md-0 text-start text-md-end order-1 order-md-2">
                      <Switch
                        color="warning"
                        onChange={e => {
                          setConsent({
                            ...consent,
                            ad_storage: e.target.checked ? "granted" : "denied",
                          })
                        }}
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="item row">
                    <div className="explanation col-12 col-md-5">
                      <p className="more">
                        {translate("cookies_more_info")}{" "}
                        <a
                          target="_blank"
                          href="/policy/terms/privacy-policy#cookies"
                          rel="noopener noreferrer"
                        >
                          {translate("cookies_more_info_link")}
                        </a>
                        .
                      </p>
                    </div>
                    <div className="explanation col-md-7">
                      <div className="buttons">
                        <div className=" d-flex justify-content-start justify-content-md-end">
                          <div
                            className="btn rounded-pill me-2"
                            onClick={() => {
                              handleConsent(consent)
                            }}
                          >
                            {translate("cookies_save")}
                          </div>
                          <div
                            className="btn rounded-pill highlight ms-2"
                            onClick={() =>
                              handleConsent({
                                functional_storage: "granted",
                                analytics_storage: "granted",
                                ad_storage: "granted",
                                personalization_storage: "denied",
                                security_storage: "denied",
                              })
                            }
                          >
                            {translate("cookies_accept")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </>
    )
  }
}

export default Consent

const Wrapper = styled.div`
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 999;
  background-color: white;
  width: 350px;
  heigth: 200px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;

  .inner {
    position: relative;
    padding: 2rem;

    .btn {
      font-size: 0.8rem;
      background: #ededed;
      color: #171717;
      font-weight: 500;
      border: 0px;
    }

    .highlight {
      background-color: rgb(251, 133, 0);
      color: white;
    }

    h4 {
      font-size: 1.1rem;
      line-height: 1.1rem;
      font-weight: 400;
    }

    p {
      font-size: 0.8rem;
      color: #777;

      font-weight: 400;
      a {
        color: black;
        text-decoration: none;
      }
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    bottom: 0;
    right: 0;
    border-radius: 0;

    .inner {
      padding: 1rem;

      h4 {
        font-size: 1rem;
      }
    }
  }
`

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0%;
  z-index: 999999998;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);

  .inner {
    position: fixed;
    top: 5%;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 999999999;
    background-color: white;
    max-width: 800px;
    max-height: 90%;
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    overflow-y: scroll;
    overflow-x: hidden;

    .content {
      padding: 2rem;

      h3 {
        font-size: 1.3rem;
        line-height: 1.5rem;
        font-weight: 500;
        margin-bottom: 1rem;
      }

      h4 {
        font-size: 1rem;
        line-height: 1.1rem;
        font-weight: 400;
      }

      p {
        color: #777;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5rem;
      }

      hr {
        margin: 1rem 0 2rem;
        padding: 0;
        opacity: 0.05;
      }

      .more {
        padding-top: 0.4rem;
        font-size: 0.875rem;
        color: black;
      }

      a {
        color: #0051ff;
        cursor: pointer;
        text-decoration: none;
      }

      .btn {
        font-size: 1rem;
        color: #171717;
        font-weight: 400;
        border: 0px;
      }

      .highlight {
        background-color: rgb(251, 133, 0);
        color: white;
        padding: 0.5rem 1.25rem;
        margin-top: -0.1rem;
        font-weight: 500;
      }
    }
  }
`
